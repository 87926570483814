function SuspenseContent() {
  return (
    <div className="w-full h-screen flex justify-center items-center text-gray-300 dark:text-gray-200 bg-base-100">
      <div className="h-20 w-20 animate-spin">
        <svg
          className="h-full w-full"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25"
            cy="25"
            r="20"
            stroke="#d3d3d3" // light gray color for 30%
            strokeWidth="5"
          />
          <path
            d="M25,5 A20,20 0 0,1 45,25"
            stroke="#fff" // white color for 70%
            strokeWidth="5"
            fill="none"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default SuspenseContent;
